import { Popup, RichTextEditorField, htmlService, useToaster } from "@maistro/components";
import { FormikErrors, useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { deleteImagesFromStorage, uploadImagesToStorage } from "api/fileApi";
import HelpBanner from "components/HelpBanner/HelpBanner";
import { ICategoryProps } from "components/shared";
import { IProjectInformation } from "features/project/types";
import useCompany from "hooks/useCompany";
import useCurrentUser from "hooks/useCurrentUser";
import usePlatformFeatures from "hooks/usePlatformFeatures";
import useReduxSelector from "hooks/useReduxSelector";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import apiErrorService from "services/apiErrorService";
import { ImageResponseDto } from "types/dtos/files/ImageResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import FeatureToggle from "types/enums/companies/FeatureToggle";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

interface IProjectDescriptionFieldProps {
    disableSubmission: boolean;
    buttonOnly: boolean;
    projectUuid: string;
    newProjectUuid: string | null;
    processText: (
        inputText: string,
        setFieldValue: (
            field: string,
            value: ICategoryProps[],
            shouldValidate?: boolean,
        ) => Promise<void | FormikErrors<IProjectInformation>>,
        categories: Array<ICategoryProps>,
    ) => void;
    existingImages?: string[];
}

const ProjectDescriptionField: React.FC<IProjectDescriptionFieldProps> = (props) => {
    const { disableSubmission, buttonOnly, projectUuid, newProjectUuid, processText } = props;
    const { values, setFieldValue } = useFormikContext<IProjectInformation>();
    const [isProjectBriefPopupOpen, setIsProjectBriefPopupOpen] = useState(false);
    const [currentBrief, setCurrentBrief] = useState("");
    const { theme } = useReduxSelector((state) => state.themeState);

    const navigate = useNavigate();
    const toast = useToaster();
    const { t } = useTranslation();

    const { isAIAssistAvailable } = usePlatformFeatures();
    const { companyHasFeature } = useCompany();
    const { userHasProjectPermission } = useCurrentUser();

    const onUploadImages = async (files: File[]): Promise<ImageResponseDto[]> => {
        const requestProjectUuid =
            props.projectUuid === "new" ? props.newProjectUuid || "new" : props.projectUuid || "new";

        const response = await uploadImagesToStorage(files, requestProjectUuid);

        if (response.data instanceof TransactionErrorDto) {
            const errorResponse = apiErrorService.getFirstErrorFromResponse(response.data);

            if (errorResponse.length < 25) {
                toast.error(t(`fileUpload.errors.${apiErrorService.getFirstErrorFromResponse(response.data)}`));
            } else {
                toast.error(errorResponse);
            }
            return [];
        }

        return response.data.files;
    };

    const onDeleteImages = async (filesToDelete: string[]): Promise<void> => {
        const response = await deleteImagesFromStorage(filesToDelete, props.projectUuid);
        if (response.data instanceof TransactionErrorDto) {
            const errorResponse = apiErrorService.getFirstErrorFromResponse(response.data);
            if (errorResponse.length < 25) {
                toast.error(t(`fileUpload.errors.${apiErrorService.getFirstErrorFromResponse(response.data)}`));
                return;
            }
            toast.error(errorResponse);
        }
    };

    const goToBriefGeneration = () => {
        navigate(
            buildPath(routes.projects.generateProjectDescription, {
                projectUuid: props.newProjectUuid ?? props.projectUuid,
            }),
        );
    };

    const showAiAssist =
        userHasProjectPermission(PermissionsEnum.CreateProject, projectUuid) &&
        isAIAssistAvailable &&
        companyHasFeature(FeatureToggle.AIAssist, true);

    const helpBanner = () => {
        if (!showAiAssist) {
            return undefined;
        }

        return (
            <HelpBanner
                title={t("projectBrief.description.guidedQuestionBannerTitle")}
                message={t("projectBrief.description.guidedQuestionBannerMessage")}
                buttonLabel={t("projectBrief.description.guidedQuestionBannerCta")}
                onClick={() => {
                    if (htmlService.hasContent(currentBrief)) {
                        setIsProjectBriefPopupOpen(true);
                    } else {
                        goToBriefGeneration();
                    }
                }}
                disabled={disableSubmission || (!newProjectUuid && projectUuid === "new")}
                buttonOnly={buttonOnly}
            />
        );
    };

    return (
        <>
            <RichTextEditorField
                testid="project-brief-description"
                label={t("projectBrief.description.label")}
                labelComponent={helpBanner()}
                name="description"
                required
                tooltip={t(`projectBrief.description.${showAiAssist ? "guidedTooltip" : "tooltip"}`)}
                onChange={(value) => {
                    setCurrentBrief(value);
                    processText(value, setFieldValue, values.categories);
                }}
                joditLicense={theme.joditLicence}
                showImageUploader
                uploadImages={onUploadImages}
                existingImages={props.existingImages}
                deleteImages={onDeleteImages}
            />
            <Popup
                title={t("popups.projectBrief.aiAssist.title")}
                message={t("popups.projectBrief.aiAssist.message")}
                isOpen={isProjectBriefPopupOpen}
                primaryActionText={t("popups.projectBrief.aiAssist.cta.primary")}
                onPrimaryAction={() => goToBriefGeneration()}
                secondaryActionText={t("popups.projectBrief.aiAssist.cta.secondary")}
                onSecondaryAction={() => setIsProjectBriefPopupOpen(false)}
                onClose={() => setIsProjectBriefPopupOpen(false)}
                variant="warning"
                testid="project-brief-generation-popup"
            />
        </>
    );
};

export default ProjectDescriptionField;
